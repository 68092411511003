///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
///

//adding for unsion
$small-min: 0;
$small-max: 640px;
$medium-min: 641px;
$medium-max: 1024px;
$medium-size: 768px;
$large-min: 1025px;
$large-max: 1400px;

// Layout
$box-model: 'border-box';
$max-width: 1366px;
$mobile-width-limit: 767px;
$tablet-width-min: 768px;
$landscape-up: 1024px;

// Media queries
// global/_media-queries.scss
$small-range: (0, 736px); // iPhone: $small-up, $small-only
$xsmall-range: (0, 400px); // Screen size smaller than iphone6
$medium-range: (737px, 768px); // iPad portrait: $medium-up, $medium-only
$large-range: (769px, 1024px); // iPad landscape: $large-up, $large-only
$xlarge-range: (1025px, 1279px); // Desktop interim: $xlarge-up, $xlarge-only
$xlarger-range: (768px, 1367px);
$xxlarge-range: (1280px); // Everything above: $xxlarge-up
$boutique-range: ($max-width); // Everything above: Boutique max-width
$xxxlarge-range: (1920px); //Larger screen: $xxxlarge-up
$boutique-range: ($max-width); // Everything above: Boutique max-width
$wide-short-range: (2, 1); // Short-wide aspect: $wide-short
$front-tall-range: (760px); // minimum height for front page tall nav
$nav-tall-range: (640px); // minimum height for nav touts
$product-normal-range: (641px, 1279px); // Desktop interim: $product-normal-up, $product-normal-only
$product-wide: (1280px); // Desktop interim: $product-wide-up
// device specific
$iphone5-range: (321px, 569px);
$iphone6-range: (375px, 667px);
$iphone6plus-range: (414px, 736px);
$device-tablet-range: (800px, 1280px);
$device-tablet-up-range: (801px); // after device tablet portrait
$device-tablet-up-landscape-range: (1281px); // after device tablet landscape
$device-ipad_mini-range: (768px, 1024px);
$device-iphone-range: (481px, 736px); // iPhone landscape
$ipad_pro_11in_portrait-width: 834px;
$ipad_pro_11in_portrait-height: 1194px;
$ipad_pro_11in_percent_adjust: 2; // Shrinks/expands $checkout-width--content and $checkout-width--sidebar by this %
$device-iphonex-range: (375px, 896px); // iPhonex landscape
$device-medium-range: (731px, 812px); // Galaxy and IphoneX Medium Range
$device-ipad-tablet-range: (768px, 1366px); // larger iPads and tablets in both portrait and landscape modes

// Typographic
$base-font-size: 15px; // 1em = 12pt = 16px = 100%.
// $base-line-height: 1.5 * $base-font-size; // change me
// Colors
$color-black: #000000; // assumed from comps
$color-white: #ffffff; // styleguide pg.5
$color-navy: #040a2b; // syleguide pg.5
$color-navy--aerin: #191f35;
$color-bright_navy: #0040c1;
$color-menu-borders--aerin: $color-navy--aerin;
$color-periwinkle-blue: #9eafff; // styleguide pg.6
$color-darker-gray: #808080;
$color-deepcove: #040a2b;
$color-periwinkle-blue--aerin: #8f92a4;
$color-periwinkle-blue--aerin-v2: #4c4e59;
$color-blue--estee_edit: #0012c9; //#0230d3
$color-bright-blue--estee_edit: #0012c9; //#2923cc// #2502fe;// #2603fe//#2400ff // #0000ff//#0130d3
$color-border--estee_edit: #cacbd3;
$color-light-gray: #cccccc; // styleguide pg.6
$color-dark-gray: #555555;
$color-dark-blue: #e0e0e0;
$color-lightest-gray: #f5f5f5;
$color-lighter-gray: #ebeaef;
$color-lighter-gray-alt: #bdbdbd;
$color-light-blue: #add8e6;
$color-light-gray--aerin: #7c7f91;
$color-light-gray--estee-edit: #7f7f7f;
$color-light-gray-white: #e5e8ed;
$color-light-gray-blue: #eaebf0; // styleguide pg.249
$color-med-dark-gray: #757575;
$color-med-light-gray: #e0e0e0;
$color-light-med-gray: #c8c8c8;
$color-light-dark-gray: #b2b2b2;
$color-medium-gray-blue: #ced0d9; //styleguide pg.40
$color-medium-gray: #a4a4ab; // styleguide pg.6
$color-medium-gray-alt: #999999; // input placeholder
$color-medium-blue: #a4a4ab; // styleguide 2 pg.4
$color-light-navy: #f6f6f5; // styleguide 2 pg.5
$color-medium-gray-new: #e4e4e6;
$color-light-navy-alt: #282d51; //footer tablet in-house psd
$color-gold: #9a7d46; // styleguide 2 pg.5
$color-gold--renutriv: #c3a36e;
$color-gold--sd-renutriv: #c3a36d;
$color-gold--renutriv-v2: #ebd79a;
$color-gray--renutriv: #b3b3b3;
$color-gray--aerin: $color-light-gray--aerin;
$color-red: #ff0000; // styleguide 2 pg.5
$color-red-error: #b00020;
$color-grayish-navy: #05092b;
$color-dark-navy: #01051e; // styleguide pg.8
$color-medium-navy: #1e2341; // styleguide pg.8
$color-medium-navy-alt: #111940; // mobile search psds
$color-gray-cs-side: #f2f3f4; // customer service comps
$color-gray-cs-callout: #f6f6f6; // customer service comps
$color-mobile-separator: #50536b;
$color-light-gray-black: #f2f3f5; // checkout side container
$color-muted-teal-aerin: #a0bdc5;
$color-muted-gray-aerin: #ececec;
$color-border-aerin: #e4e4e4;
$color-gwp-background: #e4e5e7;
$color-header-border-aerin: #bebdc2;
$color-comet: #50536b;
$color-gold-engrave: #d3b95e;
$color-gold-engrave-dark: #ba9f3f;
$color-bottle-gold: #eece90; // Engraving Overlay for Golden colour Bottle
$color-light-blue: #c8def4;
$color-medium-black: #191919;
$color-almost-black: #252323;
$color-black-opacic: rgba($color-black, 0.25);
$color-gray-light-border: #bbbbb9;
$color-gray-dark-border: #959595;
$color-gray-background: #eff0f0;
$color-sky-blue: #d0e4f5;
$color-sky-blue-medium: #467fff;
$color-light-orange: #fef2f2 !default;
$color-orange-deferred: #ee9f57;
$color-green: #07bb5b !default;
$color-mild-green: #90ee90 !default;
$color-dark-green: #06c755 !default;
$color-citrus-green: #70b000;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';
$color-sky-blue-light: #c3e3ee;
$color-light-maroon: #b84947;
$color-azure-transparent: rgba(239, 240, 240, 0.4);
$color-grey-transparent: rgba(4, 10, 43, 0.02);
$color-black-light: rgba($color-black, 0.14);
$color-grey-light: #c7c7c7;
$color-grey-nero: #1a1a1a;
$color-whisper: #e6e6e6;
$color-dark-grey: #999999;
$color-dark-border-grey: #aaaaaa;
$color-medium-green: #70b000;
$color-maroon: #dd0000;
$color-pink: #ff66b6;
$color-grey-lighter: #3f3f3f;
$color-grey-medium: #666666;
$color-light-pink: #ffe4e1;
$color-fb-blue: rgba(59, 89, 152, 0.88);
$color-danube-blue: #5f7cbb;
$color-grey-text: rgb(204, 204, 204);
$color-grey-border: rgb(214, 214, 214);
$color-maroon-transparent: rgba(176, 0, 32, 0.02);
$color-red-border: rgb(176, 0, 32);
$color-red-background: rgba(220, 53, 48, 0.05);
$color-red-error-border: rgb(220, 53, 48);
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;
$color-very-light-gray: #eeeeee;
$color-light-green: #b2fce4;
$color-blue-background: #eff4ff;
$color-light-gray-dove: #6f6f6f;
$color-light-gray-alto: #cfcfcf;
$color-re-nutriv-bg: #f5d2674d;
$color-gray-200: #e5e5e5; // [EL] GLUXRE-2004 | Track My Order
$color-pink-sherbet: #f081a3;
$color-bloody-ruby: #a71d1c;
$color-blood-organ: #660d0e;
$color-silver-chalice: #ababab;
$color-inactive-gray: #a4a4ab;
$color-black-opacity-25: rgba(0, 0, 0, 0.25);
$color-soft-mist: #e6eaef;

// Product cards style
$color-darker-grey: #222222;
$color-darkest-grey: #3a3a3a;
$color-dusty-grey: #9c9c9c;
$color-silver-grey: #cacaca;

// Extended colors
$extended_mpp_black_pearl: #040a2b;
$extended_mpp_tangaroa: #091640;

// EMEA specific colors
$color-border-grey: #e6e6e6;
$color-background-grey: #fbfbfb;
$color-blue-fb: #3c5a99;

// Typography
$brandon-text: 'Brandon Text' !default;
$brandon-text-bold: 'Brandon Text Bold' !default;
$akzidenzgroteskpro-regular: 'AkzidenzGroteskPro-Regular';
$color-black-opacic: rgba($color-black, 0.25);
$color-dark-black-opacic: rgba($color-black, 0.3);
$color-white-opacic: rgba($color-white, 0.5);
$color-medium-gray-opacic: rgba($color-medium-gray, 0.5);
$color-strong-red: #c21b2f;
$color-success: #087443 !default;
$color-error: #a80000 !default;
$color-alert: #b54708 !default;

// Abstract specific font names away
$optimalight: 'OptimaDisplayLight' !default;
$optimaregular: 'Optima Display Roman' !default;
$optimacyrillic: 'OptimaLTCyrillic';
$optimagreek: 'OptimaGreek';
$optimaUltralight: 'OptimaDisplayUltraLight';
$optimabook: 'OptimaDisplayBook';
$optimatext: 'OptimaEL Text';
$akzidenz: 'AkzidenzGrotesk W1G' !default;
$futurastdmd: 'FuturaStd-Medium';
$helvetica-neue: 'helvetica-neue';
$bb-italic: 'Bauer Bodoni W01 Italic';
$bb-roman: 'Bauer Bodoni W01 Roman';
$devinne-roman: 'De Vinne W01 Roman';
$devinne-italic: 'De Vinne W01 Italic';
$ffmarkweb-medium: 'FFMarkWebMedium';
$ffmarkweb: 'FFMarkWeb';
$essonnes: 'EssonnesDisplayRegular' !default;
$roboto-text: 'roboto-mono_regular', helvetica, arial, sans-serif !default;
$akzidenzregular: 'AkzidenzGroteskRegular' !default;
$akzidenzBQ: 'AkzidenzGroteskBQ';
$akzidenzBQBOL: 'AkzidenzGroteskBQBOL';
$renutriv-helvetica: 'helvetica-neue', 'HelveticaNeueLTStd-LtCn', sans-serif;
$renutriv-roboto: 'Roboto Condensed', sans-serif;
$renutriv-bodoni: 'Bauer Bodoni W01 Roman', 'BauerBodoniStd-Roman', serif;

// Google Fonts
$bodoni-moda-regular: 'Bodoni Moda Regular';
$bodoni-moda-italic: 'Bodoni Moda Italic';
$roboto-condensed: 'Roboto Condensed';
$arsenal: 'Arsenal';
$futura_now_reg: 'Futura Now Regular';
$futura_now_md: 'Futura Now Medium';

// LUX
$color-black-opacity-64: rgba($color-black, 0.64); //#000000A3;
$color-black-opacity-88: rgba($color-black, 0.88); //#000000E0;
$signin-box-lighter-gray: #d6d6d6; //rgb(214, 214, 214)
$lux-error: #b00020; //rgba(176, 0, 32)
$lux-error-opacity-02: rgba($lux_error, 0.02); //#b0002005
$lux-success: #44826e; //rgba(68, 130, 110)
$lux-success-background: #eefbf7; //rgba(238, 251, 247)
$lux-success-background-opacity-60: rgba($lux-success-background, 0.6); //#eefbf799
$lux-success-border: #99dfc7; //rgba(153, 223, 199)
$lux-info-icon: #bb8b60;
$lux-field-label: #757575;
$lux-field-value: #212121;
$color-facebook-blue: rgba(59, 89, 152, 0.88);
$color-danube-blue: #5f7cbb;
$optimaLT: 'OptimaLT';

// Loyalty
$font-bauerbodoni-roman: 'Bauer Bodoni W01 Roman', 'BauerBodoniStd-Roman', serif;
$font-optimadisplay-light: 'OptimaDisplayLight', serif;
$font-akzidenzgrotesk: 'AkzidenzGrotesk W1G', helvetica, arial, sans-serif;

//colorbox height for welcome and b-day popup
$colorbox-height: 1000px !important !default;

// holiday 2014
$baskerville-italic: 'BaskervilleNo2-RegIta';

// holiday 2015
$linotype-didot-roman: 'Didot W01 Roman';
$linotype-didot-italic: 'Didot W01 Italic';
$linotype-didot-bold: 'Didot W01 Bold';
$linotype-didot-headline: 'Didot W01 Headline';
$linotype-didot-of: 'Didot Open Face W01';
$linotype-didot-w01sc: 'Linotype Didot W01SC Rm SC';
$linotype-didot-bold-italic: 'Didot W01 Bold Italic';
$linotype-didot-orname: 'LinotypeDidotW95-Orname';

// Engraving
$futuralight: 'FuturaStd-Light' !default;
$futurabook: 'FuturaStd-Book' !default;
$futuramedium: 'FuturaStd-Medium' !default;
$futuraheavy: 'FuturaStd-Heavy' !default;
$optima: 'Optima' !default; //Nh6optim font
$roman: 'Timesroman' !default; //Nh4roman font
$script: 'Script' !default; //VLADIMIR font
$roman-l-monog-abc: 'Roman_L_Monog_abc' !default; //ROMAMS Font
$interlock-m-script-abc: 'Interlock-M-Script-abc' !default; //Nhinterl Font
$arialosregular: 'ArialOSRegular' !default;
$arial: 'arial' !default;
$color-brown-grey: #877e7e;

// shade filters colors
$color-shade-coralred: #ee2a25;
$color-shade-redcoral: #ae1123;

$color-shade-goldgreen: #efc8a7;
$color-shade-greengold: #6e885c;

$color-shade-mauveblue: #805666;
$color-shade-bluemauve: #374b69;

$color-shade-mauveplum: #93004c;
$color-shade-plummauve: #a23d5b;

$color-shade-nudebrown: #b0625e;
$color-shade-brownnude: #70342f;

$color-shade-pinkberry: #f72f5e;
$color-shade-berrypink: #b3223d;

$color-shade-light-light: #fdd6b1;
$color-shade-light-dark: #e9d0ac;

$color-shade-lightmedium-light: #ddb491;
$color-shade-lightmedium-dark: #edbe98;

$color-shade-medium-light: #e5ad8a;
$color-shade-medium-dark: #d59a71;

$color-shade-mediumdeep-light: #b56e38;
$color-shade-mediumdeep-dark: #aa6533;

$color-shade-deep-light: #aa6533;
$color-shade-deep-dark: #753c2b;
$color-fb-button-blue: #4267b2; //Facebook login button colour code
$color-header-border-grey: #51556c;
$color-input-border-grey: #bebebe;

// 2018 Creative Refresh
$glux-color-menu-background: #ffffff !default;
$glux-color-menu-title: #040a2b !default;
$glux-color-menu-text: #040a2b !default;
$glux-color-submenu-text: #040a2b !default;
$glux-color-menu-border-gray: #f2f2f2 !default;
$glux-color-menu-navigation: $color-dark-navy;

$color-white-transparent: rgba(255, 255, 255, 0.65);
$color-black-transparent: rgba(0, 0, 0, 0.5);
$color-black-dark-transparent: rgba(0, 0, 0, 0.64);
$color-black-darker-transparent: rgba(0, 0, 0, 0.88);
$color-blue-dark: rgb(4, 10, 43);
$color-blue-dark-transparent: rgba(4, 10, 43, 0.6);
$color-black-transparent-light: rgba(0, 0, 0, 0.2);
$color-white-button-text: rgba(255, 255, 255, 0.88);
$color-sticky-footer-shadow: rgba(50, 50, 50, 0.75);
$color-lighter-grey-white: #d7d7d7;

$color-tmo-green: #007f5f !default;
$color-tmo-dark-grey: #4f4f4f !default;
$color-tmo-red: #da1e28 !default;
$color-tmo-breadcrumb: rgba(235, 234, 239, 0.5);
$color-tmo-light-green: #f3fff8 !default;

// Gradients
// put gold gradient from styleguide pg.5  here

// Links
$link: '&:link, &:visited';
$focus: '&:focus, &:hover, &:active';

// State
$alt: '.is-alternate';
$alt-color-white: '.alt-color-white';
$sticky: '.is-sticky';
$expanded: '.is-expanded';
$opaque: '.is-opaque';
$transparent: '.is-transparent';
$active: '.js-active';
$search: '.is-search';
$nav: '.is-nav';
$min-nav: '.is-min-nav'; // reduced nav on cart pages
$renutriv: '.brand-renutriv';
$aerin: '.brand-aerin';
$sd-spp-formatter-aerin: '.sd-aerin-spp-formatter';
$sd-spp-formatter-renutriv: '.sd-renutriv-spp-formatter';
$estee_edit: '.brand-estee_edit';
$inline-qs: '.brand-estee_edit .mpp';
$cbox-qs: '.brand-estee_edit :not(.mpp)';
$compare: '.products-compare';
$landing: '.is-landing';
$dark-nav: '.is-dark-nav';

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
// Android specific image blurness fix for lower versions
$android_version_2: "html[data-useragent*='Android 2']";
$sprite-local-img: url('../img-local/sprites-s2646de84fc.png');
$ios4: "html[data-useragent*='iPhone OS 4']";

// Header item variables
$header-vertical-padding: 14px;
$header-item-height: 52px;
$header-with-searchbar-item-height: 112px; //118px;
$header-with-searchbar-height-without-nav: 94px;
$header-with-searchbar-item-height-without-ad-banner: 80px;
$header-item-padding: 22px;
$menu-toggle-width: 86px;

// Header height variables
$header-height-mobile: $header-with-searchbar-item-height;
$header-full-height: 164px; // front only
$header-collapsed-height-pc: 82px;
$header-collapsed-height-ie8: 68px;
// $header-collapsed-height: 118px; // i don't like this
$header-collapsed-height: 82px; // i don't like this
$header-site-banner-height: 70px;

// 2018 Creative refresh
$glux-header-height: 49px !default;
$glux-medium-up-header-height: 138px !default;
$glux-ipad-header-height: 80px !default;

// Transitions
$default-fade: 0.4s;
$default-transition-duration: $default-fade;
$back-to-top-fadein: 2s;

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// sets the whitespace around sprites
$sprites-spacing: 25px;

// defaults to including the sprite dimensions
$sprites-sprite-dimensions: true;

// set per-sprite customizations here
// $sprites-down-arrow-position: 100%; // set specific sprite to right side of spritesheet
// $sprites-search-position: 100%; // example
// $sprites-search_hover-position: 100%; // example
$sprites-country_chooser-position: 100%; // "for country_chooser.png, move it to right side of spriteesheet"
$sprites-country_chooser_hover-position: 100%;
$sprites-nav-arrow-right-position: 100%;
$sprites-footer_arrow_next-position: 100%;
$sprites-menu-plus-position: 100%; // customer service
$sprites-menu-minus-position: 100%; // customer service
$sprites-menu-minus-inactive-position: 100%; // customer service
$sprites-menu-plus-faq-position: 100%; // customer service
$sprites-menu-minus-faq-position: 100%; // customer service
$sprites-quickshop_mobile_close-position: 100%; // quickshop mobile
$sprites-quickshop_mobile_close_at2x-position: 100%;
$sprites-quickshop_mobile_close__tee-position: 100%; // quickshop mobile TEE
$sprites-quickshop_mobile_close_at2x__tee-position: 100%; // TEE
$sprites-select_arrow_mobile-position: 100%; // mobile selects
$sprites-select_arrow_mobile_at2x-position: 100%;
$sprites-select_arrow_mobile_hover-position: 100%;
$sprites-select_arrow_mobile_hover_at2x-position: 100%;
$sprites-caret-blue-right-position: 100%; // mobile account menu

// On our platform we have to fight css file referencing issues
$sprites-isBase64: false;
// $sprites-isBase64: true;

// rgbapng background settings
$rgbapng_path: 'rgbapng';
$rgbapng_px_size: 10;

// footer
$sticky-footer-height: 43px;
$sticky-footer-height-mobile: 37px;
$sticky-footer-drawer-height: 240px;

$footer-menu-height: 350px;
$footer-forms-height: 175px;
$footer-legal-height: 40px;
$footer-height: $sticky-footer-height + $footer-menu-height + $footer-forms-height +
  $footer-legal-height;

// columns + gutter padding for columns // styleguide p.157
$column-grid: 12;
$gutter: 0 1%;
$outer-gutter: 0 2%;
$mobile-outer-gutter-fluid: 7.8125%; //50px out of 640px // 20131015 EL_MOBILE_STYLEGUIDE.pdf pg.15
$mobile-outer-gutter: 25px;

// homnepage template variables
$template-a-image-width: 60%;
$template-b-image-width: 66%;
$template-d-image-width: 66%;
// Extra wide/short homepage bricks need some adjustment
$wide_ratio: 0.66;

//swatch size
$spp-swatch-container-mobile: 38px;
$swatch-offset-mobile: 8px;

$spp-swatch-container: 28px;
$spp-swatch-total: 22px;
$spp-swatch-half: 11px;
$spp-swatch-third: 7px;
$spp-swatch-two-third: 15px; // 1 px extra
$spp-swatch-offset: 3px;

$spp-pc-height: 768px;

$mpp-swatch-container: 30px;
$mpp-swatch-total: 18px;
$mpp-swatch-half: 9px;
$mpp-swatch-third: 6px;
$mpp-swatch-two-third: 12px; // 1 px extra
$mpp-swatch-offset: 6px;

$search-swatch-container: 24px;
$search-swatch-total: 16px;
$search-swatch-half: 8px;
$search-swatch-third: 6px;
$search-swatch-two-third: 11px; // 1 px extra
$search-swatch-offset: 4px;
$gwp_offer_code: 'gwp_sp19';

$mq-sync: small $small-min, medium $medium-min, large $large-min;

$sprites-sprites: url('/media/images/global/sprites-s38a9885fce.png');
$samples-page-width: 800px;
$samples-page-gutter: 30px;

// AliPay Configuration
$alipay-enabled: false !default;

$checkout-width: 1080px;
$checkout-width--content: percentage(702px / $checkout-width);
$checkout-width--sidebar: percentage(378px / $checkout-width);

$checkout-font-size--base: 12px;
$checkout-font-size--title: 40px;
$checkout-font-size--subtitle: 18px;
$checkout-font-size--large-subtitle: 24px;

$checkout-spacing--extra-small: 3px;
$checkout-spacing--small: 6px;
$checkout-spacing--medium: 12px;
$checkout-spacing--large: 18px;
$checkout-spacing--extra-large: 24px;

$checkout-line: 1px solid $color-light-gray;
$checkout-line-lighter-gray: 1px solid #dddddd;
$checkout-line--input: 1px solid $color-navy;
$checkout-line--input-light-gray: 1px solid $color-light-gray;

$checkout-adaptive-placeholder-parameters: (
  height: 4em,
  margin: 1em,
  border: 1px,
  border-radius: 0,
  border-color: $color-navy,
  font-size: 1em,
  label-color: $color-navy,
  active-color: $color-navy,
  valid-color: $color-navy,
  invalid-color: $color-red
);

$checkout-adaptive-placeholder-parameters--textarea: (
  height: 8em,
  margin: 1em,
  border: 1px,
  border-radius: 0,
  border-color: $color-navy,
  font-size: 1em,
  label-color: $color-navy,
  active-color: $color-navy,
  valid-color: $color-navy,
  invalid-color: $color-red,
  textarea: true
);

// Seems like these are used instead of the usual
// left and right, to account for reading direction
// changing with some languages?
$rdirection: right !default;
$ldirection: left !default;

$checkout-left: $ldirection;
$checkout-right: $rdirection;

//

$checkout-pushdown: 35px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$cart-line: 1px solid $color-light-gray; // line used everywhere
$cart-spacing: 20px; // common shared margin/paddings

$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(356px / $cart-container-width);
$cart-sidebar-width: percentage(256px / $cart-container-width); // this will override

/// Cart item values
// product
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 46%;
$desc-width-desktop: 35%;
// price
$price-width: 15%;
// replenishment
// $replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;
// total
$total-width-desktop: 10%;

// wp message
$wpmsg-margin-mobile: 0;
$wpmsg-margin-desktop: -50px auto 30px;
$wpmsg-margin-mobile-width: 100%;
$wpmsg-margin-desktop-width: 495px;

// Everything is nice and centered except the registration form, which randomly
// has stuff breaking the grid. So we can't just set the width and margin: auto,
// we have to do this:
$sign-in-form-wrapper-width: 800px;
$sign-in-form-width: 226px;
$sign-in-form-overflow-width: 300px;
$sign-in-form-margin-left: ($sign-in-form-wrapper-width - $sign-in-form-width) / 2;
$sign-in-form-registration-confirmation-width: 680px;

// var to use for reviews styling
$reviews_suffix: ' out of 5' !default;
$reviews_helpful: 'Helpful (' !default;
$reviews_not_helpful: 'Not Helpful (' !default;
$reviews_sort: 'sort: ' !default;

// z-indexes
$navigation-z-index: 100 !default;
$nav-overlay-z-index: $navigation-z-index + 1 !default;

// SD SPP Product
$sd-spp-hide-atb-aerin: false !default;
$sd-spp-hide-atb-renutriv: false !default;
$sd-spp-show-ppu: false !default;
$sd-spp-installments-price: false !default;
$sd-spp-info-content: false !default;
$sd-spp-afterpay-popup: false !default;
$sd-spp-favorite-button: false !default;
$sd-spp-olapic-widget: false !default;

$loyalty_program: false !default;

$sd_spp_sticky: true !default;

$sd-spp-add-to-bag: true !default;

// Used to for ask & answer styling.
$sd-spp-ask-answer: false !default;
$sd-spp-ask-answer-title: 'What would you like to know more about this product?' !default;
$sd-spp-ask-answer-sort: 'SORT:' !default;
$sd-spp-ask-answer-copy-text: 'ASK A QUESTION' !default;
$best-shade-message: false !default;

$vto-save-match: false !default;

// ELC BASE - SHARED Design enable
$elc_base_shared_checkout_designs: true !default;

// Loyalty Popup update.
$loyalty-popup-cr23update: false !default;

// Giftwrap Toggle Color.
$color-gw-toggle-blue: #040b2a;

$signin_error_messages: false !default;

// Loyalty Banner update.
$loyalty-banner-cr23update: false !default;
$signin-banner-cr23update: false !default;
$checkout_panel_header: false !default;
$loyalty-banner-cr24update: false !default;
