@import '../../../estee_base/scss/theme-bootstrap';

.lc-service-drawer {
  &-v2 {
    #{$rdirection}: 0;
    background: $color-white;
    bottom: 0;
    box-shadow: 0 0 4px $color-black;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 99999;
    @media #{$large-up} {
      #{$rdirection}: 5px;
      bottom: calc(60px + 4em);
      width: 400px;
    }
  }
  h3.lc-service-drawer__header-text {
    color: $color-white;
  }
  &__header-container {
    background: $color-navy;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-white;
    font-size: 25px;
    font-weight: bold;
    margin: 0;
    text-transform: inherit;
  }
  &__button {
    background: $color-navy;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 15px;
    margin: 15px 0;
    padding: 15px 10px;
    text-align: center;
    width: 100%;
  }
  &__body-container {
    font-weight: bold;
    padding: 15px;
    a:hover {
      color: $color-white;
    }
  }
  &__logo {
    background: url('/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-small-alt.png');
    background-size: 166px 23px;
    fill: $color-white;
    float: #{$rdirection};
    height: 23px;
    width: 166px;
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @media #{$medium-down} {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
}

.rounded-live-chat-button {
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-black;
  border: 2px solid $color-black;
  border-radius: 2em;
  bottom: 60px;
  color: $color-white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1em;
  position: fixed;
  z-index: 1000;
  &__down-caret {
    border-#{$rdirection}: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
  }
}

.LPMcontainer {
  visibility: hidden;
}
